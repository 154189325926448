import dynamic from "next/dynamic";
import { createPortal } from "react-dom";
import { useRef, useState, useEffect } from "react";
import { useRouter } from "next/router";
import { site } from "config";

const SignUp = dynamic(() => import("@clerk/nextjs").then((mod) => mod.SignUp));
const SignIn = dynamic(() => import("@clerk/nextjs").then((mod) => mod.SignIn));
const SignUpSignInButton = dynamic(() => import("./SignUpSignInButton"));
const Consent = dynamic(() => import("components/auth/clerk/Consent"));

import useOutsideClick from "lib/hooks/useOutsideClick";
import { getCookie, setCookie } from "utils";

import styles from "./SignUpSignInModal.module.scss";
import { useMemo } from "react";

export default function SignUpSignInModal({
  buttonMode = "icon",
  redirectUrl,
  buttonText,
}) {
  const [open, setOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [activeTabButton, setActiveTabButton] = useState(0);
  const [isSwitching, setIsSwitching] = useState(false);
  const [communicationConsent, setCommunicationConsent] = useState(true);
  const [emailAddress, setEmailAddress] = useState("");
  const [hubspotutk, setHubspotutk] = useState("");
  const [cookieReadAttempts, setCookieReadAttempts] = useState(0);

  const communicationConsentRef = useRef();

  const router = useRouter();
  const defaultRedirectUrl = `${site}/${router.asPath}`;

  const handleConsentCheckboxChange = async (event) => {
    setCommunicationConsent((prev) => !prev);

    /* We always need to store the user's email in state and pass it as an
     ** initial value because Clerk will reset all the fields when unsafeMetadata is updated */
    const emailAddressInputField = document.querySelector(
      "#emailAddress-field"
    );

    const currentEmailValue = emailAddressInputField.value;

    setEmailAddress((prev) => {
      setEmailAddress(currentEmailValue);
    });
  };

  const handleUserButtonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setIsClosing(false);
    setOpen((prev) => !prev);
    return;
  };

  const handleModalClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const handleTabSwitch = (index) => {
    setIsSwitching(true);
    setActiveTabButton(index);

    setTimeout(() => {
      setCurrentTab(index);
      setIsSwitching(false);
    }, 300);
  };

  const buttonRef = useRef();
  const portalRef = useRef(null);
  const modalRef = useOutsideClick(handleModalClose, [buttonRef]);

  useEffect(() => {
    portalRef.current = document.querySelector("#portal");

    if (process.env !== "production") {
      return;
    }

    const cookieCheckInterval = setInterval(() => {
      setCookieReadAttempts((prev) => {
        if (prev >= 20) {
          console.log("hubspotutk not found");
          clearInterval(cookieCheckInterval);
          return prev;
        }

        const hubspotutkCookie = getCookie("hubspotutk");

        if (hubspotutkCookie) {
          console.log(hubspotutkCookie);
          setHubspotutk(hubspotutkCookie);
          clearInterval(cookieCheckInterval);
        }

        return prev + 1;
      });
    }, 500);

    return () => clearInterval(cookieCheckInterval); // Cleanup on unmount
  }, []);

  // useEffect(() => {
  //   function addEventListenersToButtons({ authType = "signUp" }) {
  //     const portal = portalRef.current;

  //     const callback = function (mutationsList, observer) {
  //       for (let mutation of mutationsList) {
  //         if (mutation.type === "attributes") {
  //           if (mutation.target.classList.contains("cl-providerIcon")) {
  //             const button = mutation.target.closest("button");
  //             console.log(button);
  //             button.addEventListener("click", () => {
  //               setCookie("auth_success", false, 1);
  //             });

  //             observer.disconnect();
  //           }
  //         }
  //       }
  //     };

  //     const observer = new MutationObserver(callback);

  //     observer.observe(portal, {
  //       childList: true,
  //       subtree: true,
  //       attributes: true,
  //     });
  //   }

  //   if (currentTab === 0) {
  //     addEventListenersToButtons({ authType: "signUp" });
  //   }

  //   const signInSocialButtons = document.querySelectorAll(
  //     ".cl-signIn-root .cl-socialButtonsIconButton"
  //   );
  // }, [currentTab, open]);

  function SignInTab({ redirectUrl }) {
    return <SignIn fallbackRedirectUrl={redirectUrl} routing="hash" />;
  }

  function SignUpTab({
    communicationConsent,
    handleConsentCheckboxChange,
    redirectUrl,
    initialValues = { emailAddress },
  }) {
    return (
      <>
        <SignUp
          initialValues={initialValues}
          unsafeMetadata={{
            communicationConsent,
            hubspotutk: hubspotutk ?? null,
          }}
          routing="hash"
          fallbackRedirectUrl={redirectUrl ?? defaultRedirectUrl}
          forceRedirectUrl={redirectUrl ?? defaultRedirectUrl}
        />
        <Consent
          handleConsentCheckboxChange={handleConsentCheckboxChange}
          communicationConsentRef={communicationConsentRef}
          checked={communicationConsent}
        />
      </>
    );
  }

  const tabs = useMemo(
    () => [
      {
        component: (
          <SignUpTab
            communicationConsent={communicationConsent}
            handleConsentCheckboxChange={handleConsentCheckboxChange}
            redirectUrl={redirectUrl ?? defaultRedirectUrl}
          />
        ),
        label: "Sign Up",
      },
      {
        component: (
          <SignInTab redirectUrl={redirectUrl ?? defaultRedirectUrl} />
        ),
        label: "Sign In",
      },
    ],
    [
      communicationConsent,
      handleConsentCheckboxChange,
      redirectUrl,
      defaultRedirectUrl,
      hubspotutk,
      emailAddress,
    ]
  );

  return (
    <>
      <SignUpSignInButton
        onClick={handleUserButtonClick}
        buttonRef={buttonRef}
        mode={buttonMode}
      >
        {buttonText}
      </SignUpSignInButton>

      {open &&
        !!portalRef?.current &&
        createPortal(
          <div
            className={`${styles.modalContainer} ${open ? styles.isOpen : ""} ${
              isClosing ? styles.isClosed : ""
            }`}
          >
            <div
              onClose={() => setOpen(false)}
              ref={modalRef}
              className={`${styles.modal}`}
            >
              <button
                className={`${styles.closeButton} close-sign-up-sign-in-modal`}
                onClick={handleModalClose}
              >
                <span className={`${styles.closeIcon}`}>&times;</span>
              </button>
              <div className={`${styles.tabs}`}>
                {tabs.map((tab, index) => (
                  <button
                    key={tab.label}
                    onClick={() => handleTabSwitch(index)}
                    className={`${styles.tab} ${
                      activeTabButton === index ? styles.active : ""
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
              <section
                className={`${styles.tabContent} ${
                  isSwitching ? styles.isSwitching : ""
                }`}
              >
                {tabs[currentTab].component}
              </section>
              <div className={`${styles.scrollIndicatorContainer}`}>
                <div className={`${styles.scrollIndicator}`}></div>
              </div>
            </div>
          </div>,
          portalRef.current
        )}
    </>
  );
}
